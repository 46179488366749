import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const TagsPage = ({data}) => {
  const groups = data.allKeywordsJson.group;
  console.log(groups);

  return (
  <Layout>
    <SEO title={`COVID-19 signs organized by tag`} image={data.placeholderImage.childImageSharp.fluid.src} />
    <section>
      <h2>COVID-19 signs organized by tag</h2>
        <p>Notice an error in tagging? Please <Link to="/about">contact us</Link>.</p>
      {groups.map((group, i) => {
        return (
          <div key={i}>
            <h3>{group.fieldValue}</h3>
            {group.nodes.map((tag, i) => {
              return (
                <div className='city-entry'>
                  <Link key={i} onClick={e => { trackCustomEvent({ category: "navigation", action: "tag", label: "/tags" }) }} to={`tags/${tag.slug}`}>
                    {tag.name}
                    <span className="count">
                      {tag.locations.length}
                    </span>
                  </Link>
                </div>
              )
            })}
          </div>
        )
      })}
    </section>
  </Layout>
)}

export const query = graphql`
query AllKeywords {
  placeholderImage: file(relativePath: { eq: "banner-narrow.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  allKeywordsJson(sort: {fields: slug}) {
    group(field: keywordparent) {
      totalCount
      fieldValue
      nodes {
        name
        slug
        locations {
          id
        }
      }
    }
    totalCount
  }
}
`

export default TagsPage
